//机构
import { getRequest,postRequest,putRequest,patchRequest,deleteRequest,downloadRequest} from "@/libs/axios";
// 机构
export const getOrgList = params => {
  return getRequest("/yethan/data/org/listPage", params);
};
// 添加机构
export const addOrg = params => {
  return postRequest("/yethan/data/org", params);
};
// 修改机构
export const editOrg = params => {
  return putRequest("/yethan/data/org", params);
};
// 删除机构
export function deleteOrg(sid) {
  return deleteRequest("/yethan/data/org/"+sid);
}
// 更新机构状态
export const updateOrgQyzt = params => {
  return patchRequest("/yethan/data/org/updateOrgQyzt", params);
};
// 简单机构筛选
export const orgEasyList = params => {
  return getRequest("/yethan/data/org/easyList", params);
};
// 机构导出excel
export const exportOrgExcel = params => {
  return downloadRequest("/yethan/data/org/exportExcel", params);
};
//机构导出dbf
export const exportOrgdbf = params => {
  return downloadRequest("/yethan/data/org/exportDbf", params);
};



/* 单位 */
export const getOrgUnitsList = params => {
  return getRequest("/yethan/data/orgUnits/listPage", params);
};
// 添加单位
export const addOrgUnits = params => {
  return postRequest("/yethan/data/orgUnits", params);
};
// 修改单位
export const editOrgUnits = params => {
  return putRequest("/yethan/data/orgUnits", params);
};
// 删除单位
export function deleteOrgUnits(sid) {
  return deleteRequest("/yethan/data/orgUnits/"+sid);
}
// 更新单位状态
export const updateOrgUnitsQyzt = params => {
  return patchRequest("/yethan/data/orgUnits/updateOrgUnitsQyzt", params);
};
// 简单单位筛选
export const orgUnitsList = params => {
  return getRequest("/yethan/data/orgUnits/easyList", params);
};
// 单位导出excel
export const exportOrgUnitsExcel = params => {
  return downloadRequest("/yethan/data/orgUnits/exportExcel", params);
};
// 单位导出dbf
export const exportOrgUnitsDbf = params => {
  return downloadRequest("/yethan/data/orgUnits/exportDbf", params);
};



/* 部门 */
export const getOrgDepartmentList = params => {
  return getRequest("/yethan/data/orgDepartment/listPage", params);
};
// 添加部门
export const addOrgDepartment = params => {
  return postRequest("/yethan/data/orgDepartment", params);
};
// 修改部门
export const editOrgDepartment = params => {
  return putRequest("/yethan/data/orgDepartment", params);
};
// 删除部门
export function deleteOrgDepartment(sid) {
  return deleteRequest("/yethan/data/orgDepartment/"+sid);
}
// 更新部门状态
export const updateOrgDepartmentQyzt = params => {
  return patchRequest("/yethan/data/orgDepartment/updateOrgDepartmentQyzt", params);
};
// 简单部门筛选
export const orgDepartmentList = params => {
  return getRequest("/yethan/data/orgDepartment/easyList", params);
};
// 部门导出excel
export const exportDepartmentExcel = params => {
  return downloadRequest("/yethan/data/orgDepartment/exportExcel", params);
};
// 部门导出dbf
export const exportDepartmentDbf = params => {
  return downloadRequest("/yethan/data/orgDepartment/exportDbf", params);
};

