<!-- 单位 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210)
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getOrgUnitsList,
    updateOrgUnitsQyzt,
    deleteOrgUnits,
    addOrgUnits,
    editOrgUnits,
    orgEasyList,
  } from "@/api/admin/base/org.js"

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,
    },
    data() {
      const checkMobile = (rule, value, cb) => {
        var regex =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
        if (regex.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的手机号"));
      };

      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "单位信息",
            active: true
          }
        ],
        exportTitle: "单位信息导出",
        importTitle: "单位信息导入",
        checked: true,
        showmodal: false,
        dialogTitle: "添加单位",
        tableList: [],
        module: "JGDW",
        orgList: [],
        disabled: false,
        addForm: {
          jgdm: "",
          jgmc: "",
          dwdm: "",
          qyzt: true,
          dwmc: "",
          lxrxm: "",
          lxrdh: "",
          dz: "",
        },
        rules: {
          lxrdh: [{
              required: true,
              message: "请输入手机号",
              trigger: "blur",
            },
            {
              validator: checkMobile,
              trigger: "blur",
            },
          ],
        },
        select: {
          jgdm:""
        },
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteOrgUnits(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        getOrgUnitsList({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 获取机构列表
      getOrgList() {
        orgEasyList().then(res => {
          if (res.status) {
            this.orgList = res.data
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj) {
        let {
          sid,
          qyzt
        } = obj
        updateOrgUnitsQyzt({
          sid: sid,
          qyzt: qyzt
        }).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '状态修改成功！'
            })
          }
        })
      },
      // 添加类别
      addOrgUnits() {
        let formDate = {
          jgdm: this.addForm.jgdm,
          jgmc: this.addForm.jgmc,
          dwdm: this.addForm.dwdm,
          dwmc: this.addForm.dwmc,
          qyzt: this.addForm.qyzt,
          lxrxm: this.addForm.lxrxm,
          lxrdh: this.addForm.lxrdh,
          dz: this.addForm.dz,
        }
        addOrgUnits(formDate).then(res => {
          if (res.status) {
            this.success()
          }

        })
      },
      editOrgUnits() {
        let formDate = {
          jgdm: this.addForm.jgdm,
          jgmc: this.addForm.jgmc,
          dwdm: this.addForm.dwdm,
          dwmc: this.addForm.dwmc,
          qyzt: this.addForm.qyzt,
          lxrxm: this.addForm.lxrxm,
          lxrdh: this.addForm.lxrdh,
          dz: this.addForm.dz,
          sid: this.addForm.sid
        }
        editOrgUnits(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.addForm = {
          jgdm: "",
          jgmc: "",
          dwdm: "",
          qyzt: true,
          dwmc: "",
          lxrxm: "",
          lxrdh: "",
          dz: "",
        }

        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加单位"
        this.addForm = {
          jgdm: this.select.jgdm,
          jgmc: "",
          dwdm: "",
          qyzt: true,
          dwmc: "",
          lxrxm: "",
          lxrdh: "",
          dz: "",
        }
        this.getjgmc()
        this.getBm(this.module, "dw").then(e => {
          this.addForm.dwdm = e
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改单位"
        this.disabled = true
      },
      submit() {
        if (this.addForm.sid) {
          this.editOrgUnits()
        } else {
          this.addOrgUnits()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      getjgmc() {
        let val = this.addForm.jgdm
        let obj = this.orgList.find(k => {
          return k.jgdm === val
        })
        this.addForm.jgmc = obj.jgmc
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
     /* // 条件查询
      getlistByParam() {
        getOrgUnitsList({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
            if (this.jgmc || this.dwmc) {
              this.hasName = true
            }
          }
        })
      },*/
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }

    },
    mounted() {
      this.select.jgdm = this.$route.query.jgdm
      this.getList()
      this.getOrgList()
    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 blueBorder" clearable style="width: 15%;" placeholder="请选择机构名称"
                  v-model="select.jgdm" size="small">
                  <el-option :value="item.jgdm" v-for="item in orgList" :key="item.value" :label="item.jgmc">
                    {{item.jgmc}}
                  </el-option>
                </el-select>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button variant="outline-info" @click="addItem" class="flexList mr-2 condition">
                  <i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加单位
                </b-button>

                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module:module,condition:select})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%">序号</th>
                    <th style="width: 7%;">单位编码</th>
                    <th>单位名称</th>
                    <th style="width:8%">所属机构</th>
                    <th style="width:8%">是否启用</th>
                    <th style="width:6%">联系人</th>
                    <th style="width:8%">联系电话</th>
                    <th style="width: 20%;">地址</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.dwdm}}</td>
                    <td><a :href="'/admin/baseDepartment?jgdm='+pageData.jgdm+'&dwdm='+obj.dwdm"
                        class="font-blue">{{obj.dwmc}}</a></td>
                    <td> {{obj.jgmc}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.lxrxm}}</td>
                    <td>{{obj.lxrdh}}</td>
                    <td>{{obj.dz}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(obj)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.dwmc)"></i></td>
                  </tr>

                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 添加单位-->
    <b-modal id="addCompany" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm" :rules="rules">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属机构：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.jgdm" @change="getjgmc">
                  <option value="" disabled>请选择机构</option>
                  <option :value="item.jgdm" v-for="item in orgList" :key="item.value" :label="item.jgmc">
                    {{item.jgmc}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>单位代码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="6" :disabled="disabled" v-model="addForm.dwdm"
                  placeholder="预制可更改" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>单位名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="30" v-model="addForm.dwmc" placeholder="请输入单位名称"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="qyzt" id="exampleRadios1" value=true
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadios1"
                    class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" value=false
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadio1"
                    class="form-check-label">不启用</label></div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">联系人：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.lxrxm" placeholder="联系人姓名"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item prop="lxrdh">
            <div class="d-flex check-distri mb-3"><label>联系电话：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="tel" name="" id="" value="" maxlength="50" v-model="addForm.lxrdh" placeholder="联系电话"
                  class="form-control w-100 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所在地址：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="100" v-model="addForm.dz" placeholder="单位所在地址"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
